var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"templateForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c(_vm.templateData === undefined ? 'div' : 'b-card',{tag:"div"},[_c('b-card-header',[_c('div',{staticClass:"text-primary h2"},[_c('feather-icon',{staticClass:"mr-0 mr-sm-50",attrs:{"icon":"StarIcon","size":"25","variant":"primary"}}),_c('span',{staticClass:"d-none d-sm-inline"},[_vm._v("Información Plantilla")])],1)]),_c('b-card-body',[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"type"}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"StarIcon"}}),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('template.type')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_c('validation-provider',{attrs:{"name":_vm.$t('template.type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.templateTypes,"label":"value","value":"id","reduce":function (option) { return option.id; },"placeholder":_vm.$t('list.defaultOption')},model:{value:(_vm.templateData.type_id),callback:function ($$v) {_vm.$set(_vm.templateData, "type_id", $$v)},expression:"templateData.type_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"document"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('template.header')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"header"}},[(_vm.templateData.file_header)?_c('div',[_c('span',{staticClass:"mt-3"},[_vm._v("Actual "+_vm._s(_vm.templateData.file_header.name ? _vm.templateData.file_header.name : ''))])]):_c('div',[_c('span',{staticClass:"mt-3"},[_vm._v("Actual "+_vm._s(_vm.templateData.header ? _vm.templateData.header : ''))])]),(_vm.newTemplate)?_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('template.header'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".html","state":Boolean(_vm.templateData.file_header),"placeholder":"Seleccione el archivo o arrastrelo aquí..","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.file_header),callback:function ($$v) {_vm.$set(_vm.templateData, "file_header", $$v)},expression:"templateData.file_header"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',[_c('b-form-file',{attrs:{"accept":".html","placeholder":"Seleccione el archivo o arrastrelo aquí..","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.file_header),callback:function ($$v) {_vm.$set(_vm.templateData, "file_header", $$v)},expression:"templateData.file_header"}})],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('template.body')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"body"}},[(_vm.templateData.file_body)?_c('div',[_c('span',{staticClass:"mt-3"},[_vm._v("Actual "+_vm._s(_vm.templateData.file_body.name ? _vm.templateData.file_body.name : ''))])]):_c('div',[_c('span',{staticClass:"mt-3"},[_vm._v("Actual "+_vm._s(_vm.templateData.body ? _vm.templateData.body : ''))])]),(_vm.newTemplate)?_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('template.body'),"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".html","state":Boolean(_vm.templateData.file_body),"placeholder":"Seleccione el archivo o arrastrelo aquí..","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.file_body),callback:function ($$v) {_vm.$set(_vm.templateData, "file_body", $$v)},expression:"templateData.file_body"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',[_c('b-form-file',{attrs:{"accept":".html","placeholder":"Seleccione el archivo o arrastrelo aquí..","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.file_body),callback:function ($$v) {_vm.$set(_vm.templateData, "file_body", $$v)},expression:"templateData.file_body"}})],1)])],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"footer"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('template.footer')))]),_c('span',{staticClass:"text-danger"},[_vm._v("*")])])],1),_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label-for":"footer"}},[(_vm.templateData.file_footer)?_c('div',[_c('span',{staticClass:"mt-3"},[_vm._v("Actual "+_vm._s(_vm.templateData.file_footer.name ? _vm.templateData.file_footer.name : ''))])]):_c('div',[_c('span',{staticClass:"mt-3"},[_vm._v("Actual "+_vm._s(_vm.templateData.footer ? _vm.templateData.footer : ''))])]),(_vm.newTemplate)?_c('div',[_c('validation-provider',{attrs:{"name":_vm.$t('template.footer'),"rules":{required: true}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"accept":".html","state":Boolean(_vm.templateData.file_footer),"placeholder":"Seleccione el archivo o arrastrelo aquí..","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.file_footer),callback:function ($$v) {_vm.$set(_vm.templateData, "file_footer", $$v)},expression:"templateData.file_footer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1):_c('div',[_c('b-form-file',{attrs:{"accept":".html","placeholder":"Seleccione el archivo o arrastrelo aquí..","drop-placeholder":"Arrastre el archivo aquí...","browse-text":"Seleccionar"},model:{value:(_vm.templateData.file_footer),callback:function ($$v) {_vm.$set(_vm.templateData, "file_footer", $$v)},expression:"templateData.file_footer"}})],1)])],1)],1),_c('b-row'),_c('hr'),_c('b-row',[_c('b-col',[_c('router-link',{attrs:{"to":{name: 'apps-templates-list'}}},[_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1"},[_vm._v(" "+_vm._s(_vm.$t('actions.back'))+" ")])],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","type":"submit","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$t('actions.save'))+" ")])],1)],1)],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }