<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="templateData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching template data
      </h4>
    </b-alert>

    <div class="text-primary h2">
      <feather-icon
        icon="UserIcon"
        size="25"
        class="mr-0 mr-sm-50"
        variant="primary"
      />
      <span class="d-none d-sm-inline" v-if="newTemplate">Crear plantilla</span>
      <span class="d-none d-sm-inline" v-else>Editar plantilla</span>
    </div>
    <template-edit
      :template-data="templateData"
      :template-types="templateTypes"
      :new-template="newTemplate"
      class="mt-2 pt-75"
    />
  </div>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import router from '@/router'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import TemplateStoreModule from '../storeModule'
import TemplateEdit from './EditTemplate.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    TemplateEdit,
  },
  data() {
    return {
      newTemplate: true,
      templateTypes: [],
      templateData: {},
      TEMPLATE_APP_STORE_MODULE_NAME: 'app-template'
    }
  },
  async mounted() {
    // Register module
    if (!store.hasModule(this.TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(this.TEMPLATE_APP_STORE_MODULE_NAME, TemplateStoreModule)
   
    if (router.currentRoute.params.id !== undefined) {
      this.newTemplate = false
      await this.fetchTemplate()
    }
    else {
      this.templateData = {status: 1}
    }
    this.templateTypes = await this.fetch_key_value('template_type')
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(this.TEMPLATE_APP_STORE_MODULE_NAME)
  },
  methods: {
    async fetch_key_value(type, deep, parent) {
      return store.dispatch('app-template/fetch_key_value', { type: type, deep: deep, parent: parent }).then(response => {
        var list = response.data.items
        list.unshift({ id: -1, key: this.$t('list.defaultOption'), value: this.$t('list.defaultOption') })
        return list
      }).catch(error => {
        if (error.response.status === 404) {
          return []
        }
      })
    },
    fetchTemplate() {
      store.dispatch('app-template/fetchTemplate', { id: router.currentRoute.params.id })
      .then(response => { 
        this.templateData = response.data 
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.templateData)
          templateData = undefined
        }
      })
    },
    save(templateData, type_id) {
      console.info('templateData:', templateData)
      if (this.newTemplate) {
        store.dispatch('app-template/addTemplate', {templateData: templateData, type_id: type_id})
        .then(response => { 
          this.templateData = response.data 
          this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Plantilla creada',
                icon: 'SaveIcon',
                variant: 'success',
                text: `La plantilla ${this.templateData.type.value}. Fue creada correctamente`,
              },
            })
            this.$router.replace({ name: 'apps-templates-list' })
        })
        .catch(error => {
          if (error.response.status === 404) {
            templateData.value = undefined
          }
          if (error.response.status === 400) {
            if (error.response.data && error.response.data.message == 'template exists') {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Nombre de plantilla ya existe',
                  icon: 'ErrorIcon',
                  variant: 'danger',
                  text: `La plantilla ${this.templateData.type.value} ya existe en el sistema`,
                },
              })
            }
            
          }
        }) 
      }
      else {
        store.dispatch('app-template/editTemplate', { id: router.currentRoute.params.id, type_id: templateData, type_id: type_id, templateData: templateData })
        .then(response => { 
          this.templateData = response.data 
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Plantilla actualizada',
              icon: 'SaveIcon',
              variant: 'success',
              text: `La plantilla ${this.templateData.type.value}. Fue actualizado correctamente`,
            },
          })
          this.$router.replace({ name: 'apps-templates-list' })
        })
        .catch(error => {
          if (error.response.status === 404) {
            templateData.value = undefined
          }
        })        
      }
    },
  }
}
</script>

<style>

</style>
