<template>
  <div>
    <validation-observer
      ref="templateForm"
      #default="{ invalid }"
    >
      <b-form
        @submit.prevent="save"
      >
        <!-- Template Info: Input Fields -->
        <div :is="templateData === undefined ? 'div' : 'b-card'">
          <b-card-header>
            <div class="text-primary h2">
              <feather-icon
                icon="StarIcon"
                size="25"
                class="mr-0 mr-sm-50"
                variant="primary"
              />
              <span class="d-none d-sm-inline">Información Plantilla</span>
            </div>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="type"
                >
                  <feather-icon
                    icon="StarIcon"
                    class="mr-75"
                  />
                  <span class="font-weight-bold">{{ $t('template.type') }}</span><span class="text-danger">*</span>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('template.type')"
                    rules="required"
                  >
                    <v-select
                      v-model="templateData.type_id"
                      :options="templateTypes"
                      label="value"
                      value="id"
                      :reduce="option => option.id"
                      :placeholder="$t('list.defaultOption')"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="document"
                >
                  <span class="font-weight-bold">{{ $t('template.header') }}</span><span class="text-danger">*</span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="header"
                >
                  <div v-if="templateData.file_header">
                    <span class="mt-3">Actual {{ templateData.file_header.name ? templateData.file_header.name : '' }}</span>
                  </div>
                  <div v-else>
                    <span class="mt-3">Actual {{ templateData.header ? templateData.header : '' }}</span>
                  </div>
                  <div v-if="newTemplate">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('template.header')"
                      rules="required"
                    >
                      <b-form-file
                        v-model="templateData.file_header"
                        accept=".html"
                        :state="Boolean(templateData.file_header)"
                        placeholder="Seleccione el archivo o arrastrelo aquí.."
                        drop-placeholder="Arrastre el archivo aquí..."
                        browse-text="Seleccionar"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-file
                      v-model="templateData.file_header"
                      accept=".html"
                      placeholder="Seleccione el archivo o arrastrelo aquí.."
                      drop-placeholder="Arrastre el archivo aquí..."
                      browse-text="Seleccionar"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <span class="font-weight-bold">{{ $t('template.body') }}</span><span class="text-danger">*</span>

              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="body"
                >
                  <div v-if="templateData.file_body">
                    <span class="mt-3">Actual {{ templateData.file_body.name ? templateData.file_body.name : '' }}</span>
                  </div>
                  <div v-else>
                    <span class="mt-3">Actual {{ templateData.body ? templateData.body : '' }}</span>
                  </div>
                  <div v-if="newTemplate">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('template.body')"
                      :rules="{required: true}"
                    >
                      <b-form-file
                        v-model="templateData.file_body"
                        accept=".html"
                        :state="Boolean(templateData.file_body)"
                        placeholder="Seleccione el archivo o arrastrelo aquí.."
                        drop-placeholder="Arrastre el archivo aquí..."
                        browse-text="Seleccionar"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-file
                      v-model="templateData.file_body"
                      accept=".html"
                      placeholder="Seleccione el archivo o arrastrelo aquí.."
                      drop-placeholder="Arrastre el archivo aquí..."
                      browse-text="Seleccionar"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="footer"
                >
                  <span class="font-weight-bold">{{ $t('template.footer') }}</span><span class="text-danger">*</span>
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-form-group
                  label-for="footer"
                >
                  <div v-if="templateData.file_footer">
                    <span class="mt-3">Actual {{ templateData.file_footer.name ? templateData.file_footer.name : '' }}</span>
                  </div>
                  <div v-else>
                    <span class="mt-3">Actual {{ templateData.footer ? templateData.footer : '' }}</span>
                  </div>
                  <div v-if="newTemplate">
                    <validation-provider
                      #default="{ errors }"
                      :name="$t('template.footer')"
                      :rules="{required: true}"
                    >
                      <b-form-file
                        v-model="templateData.file_footer"
                        accept=".html"
                        :state="Boolean(templateData.file_footer)"
                        placeholder="Seleccione el archivo o arrastrelo aquí.."
                        drop-placeholder="Arrastre el archivo aquí..."
                        browse-text="Seleccionar"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </div>
                  <div v-else>
                    <b-form-file
                      v-model="templateData.file_footer"
                      accept=".html"
                      placeholder="Seleccione el archivo o arrastrelo aquí.."
                      drop-placeholder="Arrastre el archivo aquí..."
                      browse-text="Seleccionar"
                    />
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row />
            <hr>
            <b-row>
              <b-col>
                <router-link :to="{name: 'apps-templates-list'}">
                  <b-button
                    class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  >
                    {{ $t('actions.back') }}
                  </b-button>
                </router-link>
                <b-button
                  variant="primary"
                  type="submit"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :disabled="invalid"
                >
                  {{ $t('actions.save') }}
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle,
  BFormCheckbox, BInputGroup, BInputGroupAppend, BBadge, BCollapse, BImg, BCardBody, BFormFile,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-select.scss'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BBadge,
    BCollapse,
    BImg,
    ValidationProvider,
    ValidationObserver,
    BCardBody,
    BFormFile,
    vSelect,
  },
  props: {
    templateData: {
      type: Object,
      required: true,
    },
    newTemplate: {
      type: Boolean,
      required: true,
    },
    templateTypes: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      // validation
      required,
    }
  },
  methods: {
    resolveTemplateStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveTemplateStatusMessage(status) {
      if (status == 0) return this.$t('template.statusValue.inactive')
      return this.$t('template.statusValue.active')
    },
    save() {
      this.$refs.templateForm.validate().then(success => {
        if (success) {
          const InstFormData = new FormData()
          InstFormData.append('header', this.templateData.file_header)
          InstFormData.append('body', this.templateData.file_body)
          InstFormData.append('footer', this.templateData.file_footer)
          this.$parent.save(InstFormData, this.templateData.type_id)
        }
      })
    },
  },
}
</script>

<style>

</style>
